.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-input {
  width: 100%;
}
