.user-menu-container {
    position: relative;
  }
  
  .user-icon {
    cursor: pointer;
    font-size: 24px;
  }
  
  .user-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px;
  }
  
  .user-menu-item {
    margin-bottom: 20px;
  }
  
  .user-menu-item button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 16px;
  }
  