html,
body,
.App,
#root,
.h-100 {
  height: 100%;
  margin: 0;
  padding: 0;
}

.position-relative {
  position: relative;
}

.table-container {
  overflow: hidden;
  min-height: 80vh;
  max-height: 80vh;
}

.card-body {
  height: 85vh;
  max-height: 85vh;
  min-height: 85vh;
}

.scrollable-content {
  max-height: 75vh;
  min-height: 75vh;
  overflow-y: auto;
}

.no-padding {
  padding: 1 !important;
  margin: 0 !important;
}

.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-padding-r {
  padding-right: 0 !important;

}
.Card-map{
  height: 18px;
}